import React, { useEffect } from "react";
import "./ListItemEL.scss";
import Card from "react-bootstrap/Card";
import { Col, Container, Row } from "react-bootstrap";
import ProgressBar from "react-bootstrap/ProgressBar";
import { Button } from "@mui/material";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {
  PlayArrow,
  KeyboardArrowRightRounded,
  LowPriority,
} from "@mui/icons-material";
import { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Navbar from "../../Pages/NavBar";
import axios from "axios";
import { useContext } from "react";
import { Contexts } from "../../../contexts/Contexts";
import { TreeDataState, CustomTreeData } from "@devexpress/dx-react-grid";
import {
  Table as TableDevExpress,
  Grid,
  TableHeaderRow,
  TableTreeColumn,
} from "@devexpress/dx-react-grid-material-ui";
import { useTranslation } from "react-i18next";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Stack from "@mui/material/Stack";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import BasicModel from "../../Components/ListItemML/popdescription/BasicModal";
import { setDefaultNamespace } from "i18next";
import { appInsights } from "../../../appInsight";
import {
  PUBLIC_URL,
  LMS_DEV,
  LMS_QA,
  PUBLIC_URL_API2,
  formatTime,
  DOMAIN_ID,
} from "./../Common/Constants.js";
import swal from "sweetalert";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  ml50: {
    [theme.breakpoints.down("md")]: {
      marginLeft: "50px !important",
    },
    [theme.breakpoints.down("xs")]: {
      marginLeft: "5px !important",
      marginTop: "20px !important",
    },
  },
  mt30: {
    [theme.breakpoints.down("xs")]: {
      marginTop: "30px !important",
    },
  },
  mt20: {
    [theme.breakpoints.down("xs")]: {
      marginTop: "20px !important",
    },
  },
}));

const getChildRows = (row, rootRows) => (row ? row.items : rootRows);

function createData(
  posts: []
  // name: string,
  // calories: string,
  // fat: string,
  // carbs: string,
  // protein: string,
  // price: string
) {
  return {
    name: posts[0]?.VC_CRS_LNCH_NM,
    lastAccessed: posts[0]?.DT_FRST_ACCSS_DT,

    resultPostNodes: posts.map((item) => ({
      name: item?.VC_ND_NM,
      type: item?.VC_KNWLDG_ASST_TYP_NM,
      lastAccessed: item?.DT_FRST_ACCSS_DT,
      attempsLeft: item?.NM_PRGRSS,
      status: item?.NM_PRGRSS,
      // action :
      key: item.NM_ND_ID,
    })),
  };
}

const renderAction = () => {
  return <PlayArrow />;
};

function ClassRoomCreateData(classRoomGridData: []) {
  classRoomGridData = Array.from(classRoomGridData);
  let tempArray = classRoomGridData?.map((item) => ({
    offerCode: item?.VC_ILT_CD,
    offerName: item?.VC_ILT_NM,
    startDate: item?.DT_STRT_DT,
    endDate: item?.DT_END_DT,
    venue: item?.VC_VN_NM,
  }));
  return tempArray;
}
function removeTags(str) {
  if (str === null || str === "" || str === undefined) return "NA";
  else str = str.toString();

  // Regular expression to identify HTML tags in
  // the input string. Replacing the identified
  // HTML tag with a null string.
  return str.replace(/(<([^>]+)>)/gi, " ");
}
const handlePlay = (NM_RSRC_ID, VC_UPLDD_FL_NM, totalAttempt) => {
  if (totalAttempt > 10) {
    alert("You have no attempt left.");
  } else {
    window.location.href =
      "https://qscfmjelmsdt.blob.core.windows.net/qscfmjelmscont/WCR/WCRContentDirectory/" +
      `${NM_RSRC_ID}` +
      "/" +
      `${VC_UPLDD_FL_NM}` +
      "?sp=r&st=2023-11-01T05:55:54Z&se=2025-10-31T13:55:54Z&spr=https&sv=2022-11-02&sr=c&sig=cHyf5JR780DahBtYZYFPLZPVjkcv3DchtO%2FPs2OVWoM%3D";
  }
};

function RowMUI(props: { row: ReturnType<typeof createData> }) {
  const { row } = props;
  const [open, setOpen] = React.useState(true);
  const { startBtnGlobal, setstartBtnGlobal } = useContext(Contexts);
  const navigate = useNavigate();
  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell component="th" scope="row">
          {row.name}
        </TableCell>
        <TableCell align="right"></TableCell>
        <TableCell align="right">{row.lastAccessed}</TableCell>
        <TableCell align="right"></TableCell>
        <TableCell align="right"></TableCell>
        <TableCell align="right"></TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell style={{ fontWeight: "Bold" }}>Name</TableCell>
                    <TableCell style={{ fontWeight: "Bold" }}>Type</TableCell>
                    <TableCell style={{ fontWeight: "Bold" }} align="right">
                      Last Accessed
                    </TableCell>
                    <TableCell style={{ fontWeight: "Bold" }} align="right">
                      Attempts Left
                    </TableCell>
                    <TableCell style={{ fontWeight: "Bold" }} align="right">
                      Status
                    </TableCell>
                    {startBtnGlobal && (
                      <TableCell style={{ fontWeight: "Bold" }} align="right">
                        Action
                      </TableCell>
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.resultPostNodes.map((historyRow) => (
                    <TableRow key={historyRow.key}>
                      <TableCell component="th" scope="row">
                        {historyRow.name}
                      </TableCell>
                      <TableCell>{historyRow.type}</TableCell>
                      <TableCell align="right">
                        {historyRow.lastAccessed}
                      </TableCell>
                      <TableCell align="right">
                        {historyRow.attemptsLeft}
                      </TableCell>
                      <TableCell align="right">{historyRow.status}</TableCell>
                      {startBtnGlobal && (
                        <TableCell align="right">
                          <PlayArrow
                            onClick={() =>
                              handlePlay(
                                historyRow.NM_RSRC_ID,
                                historyRow.VC_UPLDD_FL_NM,
                                historyRow.attemptsLeft
                              )
                            }
                          />
                        </TableCell>
                      )}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

function ClassRoomRowMUI(props: { row: ReturnType<typeof createData> }) {
  const { row } = props;
  const { t } = useTranslation();
  const [whiteListedEnrollID, setwhiteListedEnrollID] = useState();
  const { gblEnrollDisable } = useContext(Contexts);
  const { setgblEnrollDisable } = useContext(Contexts);
  const [errorMessage, seterrorMessage] = useState("");
  const [clsroomdialogBoxOpen, setclsroomdialogBoxOpen] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const handleclsroomDialogBoxClose = (event) => {
    seterrorMessage("");
    setclsroomdialogBoxOpen(false);
  };

  const handleEnroll = async (event, id) => {
    var rowId = row.NM_ILT_ID;

    appInsights.trackException({
      error: new Error("some error"),
      severityLevel: LowPriority,
    });
    appInsights.trackEvent({
      name: "Handle enroll event on List Item EL",
      properties: { rowId },
    });
    //get current date
    //get end date of the row
    //if(current date > end date)
    //then give popup alert and return
    var End_date = row?.DT_END_DT?.split("T")[0];
    const current = new Date();
    var year = current.getFullYear();
    var month = current.getMonth() + 1;
    var day = current.getDate();
    if (month < 10) {
      month = "0" + month;
    }
    if (day < 10) {
      day = "0" + day;
    }
    const currDate = `${day}/${month}/${year}`;

    let array = End_date.split("-");
    if (array[0] < year) {
      <BasicModel Description={"End Date Already Been Passed"} />;
      alert("End Date Already Been Passed");
      return;
    }

    if (array[0] == year && array[1] < month) {
      <BasicModel Description={"End Date Already Been Passed"} />;
      alert("End Date Already Been Passed");
      return;
    }

    if (array[0] == year && array[1] == month && array[2] < day) {
      <BasicModel Description={"End Date Already Been Passed"} />;
      alert("End Date Already Been Passed");
      return;
    }

    if (row.NMNTN_STTS === 1) {
      seterrorMessage(t("rbkey_ILTNmntnFllMSG"));
      setclsroomdialogBoxOpen(true);
    } else {
      setwhiteListedEnrollID(rowId);
      let tempid = rowId;
      setgblEnrollDisable(true);

      try {
        await axios
          .post(
            PUBLIC_URL +
              "/api/ClassRoomTraining/AvailableLearning/ILTSelfNomination",
            {
              learnerId: sessionStorage.getItem("userId"),
              iltId: parseInt(tempid),
              isLoginUser: true,
              strWorkflowType: "",
              isBlended: true,
            }
          )
          .then((response) => {
            try {
              axios
                .post(
                  PUBLIC_URL + "/api/Email/AvailableLearning/ILTSelfNomMail",
                  {
                    CorporateId:
                      sessionStorage.getItem("userDomain") !== null
                        ? sessionStorage.getItem("userDomain")
                        : DOMAIN_ID,
                    Language: "en-us",
                    LearnerId: sessionStorage.getItem("userId"),
                    ILTId: parseInt(tempid),
                  }
                )
                .then((response) => {});
            } catch (err) {
              appInsights.trackException({ error: err, ClassRoomRowMUI });
            }
          });
      } catch (err) {
        appInsights.trackException({ error: err, ClassRoomRowMUI });
      }

      navigate("/detailsML", {
        state: {
          course_id: location.state.course_id,
          type: location.state.type,
          course_name: location.state.course_name,
          start_date: location.state.start_date,
          end_date: location.state.end_date,
          credit_hours: location.state.credit_hours,
          progress: location.state.progress,
          description: location.state.description,
          topic_name: location.state.topic_name,
          imgSrc: location.state.imgSrc,
          VC_ILT_CD: location.state.VC_ILT_CD,
          childId: tempid,
          isDetail: "true",
          iltId: parseInt(tempid),
          certificate_prgrm_flag: location.state.certificate_prgrm_flag,
        },
      });
    }
  };

  var startDateTemp = new Date(row?.DT_STRT_DT.split("T")[0]);

  startDateTemp.setDate(startDateTemp.getDate() + 1);

  var startDateLocal = startDateTemp.toISOString().split("T")[0];

  return (
    <React.Fragment>
      <Dialog
        fullWidth
        open={clsroomdialogBoxOpen}
        onClose={handleclsroomDialogBoxClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"There are following errors"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {/* add the input forms here */}
            {errorMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setclsroomdialogBoxOpen(false);
              seterrorMessage("");
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <TableRow id={row.NM_ILT_ID} sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell component="th" scope="row">
          {row.VC_ILT_CD}
        </TableCell>
        <TableCell align="right">{row.VC_ILT_NM}</TableCell>
        {/* <TableCell align="right">{row?.DT_STRT_DT?.split("T")[0]}</TableCell> */}
        <TableCell align="right">{startDateLocal}</TableCell>
        <TableCell align="right">{row?.DT_END_DT?.split("T")[0]}</TableCell>
        <TableCell align="right">{row.VC_VN_NM}</TableCell>
        <TableCell align="right">{row.INSTRUCTOR}</TableCell>

        {(row.NMNTN_STTS === "X" || row.NMNTN_STTS === null) && (
          <TableCell align="right">
            <Button
              id={`enroll_${row.NM_ILT_ID}`}
              disabled={gblEnrollDisable}
              size="small"
              onClick={(event, id) => handleEnroll(event, id)}
              endIcon={<KeyboardArrowRightRounded />}
            >
              {t("rbkey_enrollLBL")}
            </Button>
          </TableCell>
        )}

        {row.NMNTN_STTS !== "X" && row.NMNTN_STTS !== null && (
          <TableCell align="right">
            <Button
              id={`enroll_${row.NM_ILT_ID}`}
              disabled={true}
              size="small"
              onClick={(event, id) => handleEnroll(event, id)}
              endIcon={<KeyboardArrowRightRounded />}
            >
              Enrolled
            </Button>
          </TableCell>
        )}
      </TableRow>
    </React.Fragment>
  );
}

export const ListItemEL = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const now = 60;
  const [elearningProgramGrid, setelearningProgramGrid] = useState(false);
  const [classroomTrainingGrid, setclassroomTrainingGrid] = useState(true);
  const [classroomData, setclassroomData] = useState([]);
  const [classRoomGridData, setclassRoomGridData] = useState([]);
  const { startBtnGlobal, setstartBtnGlobal } = useContext(Contexts);
  const [startbtnVisibility, setstartbtnVisibility] = useState(true);
  const { setgblEnrollDisable } = useContext(Contexts);
  const { gblEnrollDisable } = useContext(Contexts);
  const [EL_P_ExpiredFlag, setEL_P_ExpiredFlag] = useState(false);
  const [workFlowStatus, setworkFlowStatus] = useState();
  const [validationMessage, setvalidationMessage] = useState();
  const [mailDetails, setmailDetails] = useState();
  const [isValidationErrorPresent, setisValidationErrorPresent] =
    useState(false);
  const [dialogBoxOpen, setdialogBoxOpen] = useState(false);
  const [data, setdata] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [equivalenceData, setEquivalenceData] = useState([]);
  const [equivalenceFlag, setEquivalenceFlag] = useState(false);
  const [whiteListedEnrollID, setwhiteListedEnrollID] = useState();
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const navigate = useNavigate();
  const location = useLocation();
  const [columns] = useState([
    {
      name: "VC_Node_NM",
      title: `${t("rbkey_CrsNmLBL")}`,
      getCellValue: (row) => (row.VC_Node_NM === null ? "NA" : row.VC_Node_NM),
    },
    {
      name: "VC_KAT_NM",
      title: `${t("rbkey_TypLBL")}`,
      getCellValue: (row) => (row.VC_KAT_NM === null ? "NA" : row.VC_KAT_NM),
    },
    {
      name: "DT_LST_ACCSS_DT",
      title: `${t("rbkey_LstAccssdDtLBL")}`,
      getCellValue: (row) =>
        row.DT_LST_ACCSS_DT === null || row.NM_CNTNT_ID == "0"
          ? "NA"
          : row.DT_LST_ACCSS_DT.split("T")[0],
    },
    {
      name: "NM_ATTMPTS",
      title: `${t("rbkey_AttmptsLftLBL")}`,
      getCellValue: (row) =>
        row.NM_ATTMPTS == null ||
        row.VC_KAT_NM == "Assignment" ||
        row.VC_KAT_NM == "Home Work"
          ? "NA"
          : row.NM_ATTMPTS,
    },
    {
      name: "NM_PRGRSS",
      title: `${t("rbkey_SttsLBL")}`,
      getCellValue: (row) => (row.NM_PRGRSS === null ? "NA" : row.NM_PRGRSS),
    },
  ]);

  const [tableColumnExtensions] = useState([
    { columnName: "VC_Node_NM", width: 300 },
    { columnName: "VC_Node_NM", fontWeight: "bold" },
  ]);

  useEffect(() => {
    console.log(location.state.imgSrc);
    if (location.state.validationforuser == false) {
      setEL_P_ExpiredFlag(true);

      setdialogBoxOpen(true);
      setvalidationMessage(location.state.validationforusermessage);
    }
  }, [location]);

  const handleValidation = (e) => {
    if (location.state.type === "eLearning") {
      axios
        .get(
          PUBLIC_URL +
            "/api/ELearning/AvailableLearning/EL/SelfNominationValidation",
          {
            params: {
              CourseLaunchId: 123,
              UserId: 123,
              StrWorkflowType: "",
              CorporateId: 123,
            },
          }
        )
        .then(function (response) {
          //store the response in some usestate
          setworkFlowStatus(response.data);
          setmailDetails(response.data.StrWorkFlowStatus);

          if (mailDetails !== null && mailDetails !== undefined) {
            if (mailDetails === "M") {
              setvalidationMessage(t("rbkey_AccssCrsDndMSG"));
              setisValidationErrorPresent(true);
            } else if (mailDetails === "N") {
              //add auto mailer code
            } else if (mailDetails === "P") {
              setvalidationMessage(t("rbkey_CrsRqstMSG"));
              setisValidationErrorPresent(true);
            } else if (mailDetails === "R") {
              setvalidationMessage(t("rbkey_CrsRjctdMSG"));
              setisValidationErrorPresent(true);
            }
          }

          if (workFlowStatus !== null && workFlowStatus !== undefined) {
            if (workFlowStatus.BT_CN_ACSS_CRS) {
              if (workFlowStatus.CH_WRKFLW_STTS === "R") {
                setvalidationMessage(t("rbkey_CrsRjctdMSG"));
                setisValidationErrorPresent(true);
              } else if (workFlowStatus.CH_WRKFLW_STTS === "P") {
                setvalidationMessage(t("rbkey_CrsRqstMSG"));
                setisValidationErrorPresent(true);
              } else if (workFlowStatus.CH_WRKFLW_STTS === "N") {
                // mailer code goes here
              }
            }
          } else {
            setvalidationMessage(t("rbkey_AccssCrsDndMSG"));
            setisValidationErrorPresent(true);
          }
        });
    } else if (location.state.type === "Program") {
      axios
        .get(
          PUBLIC_URL +
            "/api/Program/AvailableLearning/P/SelfNominationValidation",
          {
            params: {
              CourseLaunchId: 123,
              UserId: 123,
              StrWorkflowType: "",
              CorporateId: 123,
            },
          }
        )
        .then(function (response) {
          //store the response in some usestate
          setworkFlowStatus(response.data);
          setmailDetails(response.data.StrWorkFlowStatus);

          if (mailDetails !== null && mailDetails !== undefined) {
            if (mailDetails === "M") {
              setvalidationMessage(t("rbkey_AccssCrsDndMSG"));
              setisValidationErrorPresent(true);
            } else if (mailDetails === "N") {
              //add auto mailer code
            } else if (mailDetails === "P") {
              setvalidationMessage(t("rbkey_CrsRqstMSG"));
              setisValidationErrorPresent(true);
            } else if (mailDetails === "R") {
              setvalidationMessage(t("rbkey_CrsRjctdMSG"));
              setisValidationErrorPresent(true);
            }
          }

          if (workFlowStatus !== null && workFlowStatus !== undefined) {
            if (workFlowStatus.BT_CN_ACSS_CRS) {
              if (workFlowStatus.CH_WRKFLW_STTS === "R") {
                setvalidationMessage(t("rbkey_CrsRjctdMSG"));
                setisValidationErrorPresent(true);
              } else if (workFlowStatus.CH_WRKFLW_STTS === "P") {
                setvalidationMessage(t("rbkey_CrsRqstMSG"));
                setisValidationErrorPresent(true);
              } else if (workFlowStatus.CH_WRKFLW_STTS === "N") {
                // mailer code goes here
              }
            }
          } else {
            setvalidationMessage(t("rbkey_AccssCrsDndMSG"));
            setisValidationErrorPresent(true);
          }
        });
    }

    handleStartbtn();
  };

  const handleStartbtn = () => {
    if (isValidationErrorPresent) {
      setdialogBoxOpen(true);
    } else {
      setstartBtnGlobal(true);
      setstartbtnVisibility(false);

      axios
        .post(
          PUBLIC_URL + "/api/ELearning/AvailableLearning/EL/SelfNomination",
          {
            corporateId:
              sessionStorage.getItem("userDomain") !== null
                ? sessionStorage.getItem("userDomain")
                : DOMAIN_ID,
            courseLaunchId: location.state.course_id,
            userId: sessionStorage.getItem("userId"),
          }
        )
        .then((response) => {
          navigate("/detailsML", {
            state: {
              course_id: location.state.course_id,
              type: location.state.type,
              course_name: location.state.course_name,
              start_date: location.state.start_date,
              end_date: location.state.end_date,
              credit_hours: location.state.credit_hours,
              progress: location.state.progress,
              description: location.state.description,
              VC_ILT_CD: location.state.VC_ILT_CD,
              imgSrc: location.state.imgSrc,
              topic_name: location.state.topic_name,
              certificate_prgrm_flag: location.state.certificate_prgrm_flag,
            },
          });
        });
    }
  };
  const GetEL_P_Details = () => {
    axios
      .get(PUBLIC_URL + "/api/Program/MyLearning/Program/Details", {
        params: {
          naRbkey: "NA",
          courseLaunchId: location.state.course_id,
          userId: sessionStorage.getItem("userId"),
          selectedCultureMlt: "en-US",
        },
      })
      .then((res) => {
        if (res.data.length > 0) {
          setdata(res.data);
        } else {
          setEL_P_ExpiredFlag(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const GetCLS_Training_Details = () => {
    axios
      .get(PUBLIC_URL + "/api/ClassRoomTraining/ILTDetails", {
        params: {
          userId: sessionStorage.getItem("userId"),
          iLTId: location.state.course_id,
          sessionId: "en-US",
        },
      })
      .then((res) => {
        if (res.data.length === 0) {
          setEL_P_ExpiredFlag(true);
        } else {
          setclassroomData(res.data);

          axios
            .get(
              PUBLIC_URL +
                "/api/ClassRoomTraining/AvailableLearning/ILTOfferList",
              {
                params: {
                  corporateId:
                    sessionStorage.getItem("userDomain") !== null
                      ? sessionStorage.getItem("userDomain")
                      : DOMAIN_ID,
                  ILTId: location.state.course_id,
                  moduleName: "Training",
                  userId: sessionStorage.getItem("userId"),
                  sessionId: "en-US",
                },
              }
            )
            .then((res) => {
              if (res.data.Table.length === 0) {
                setEL_P_ExpiredFlag(true);
              } else {
                setclassRoomGridData(res.data);
                res.data.Table?.forEach((element) => {
                  if (
                    element.NMNTN_STTS !== "X" &&
                    element.NMNTN_STTS !== null
                  ) {
                    setgblEnrollDisable(true);
                  } else {
                    setgblEnrollDisable(false);
                  }
                });

                let arr = new Array();
                res.data.Table?.map((item, index) => {
                  arr[index] = item.NMNTN_STTS;
                });

                if (arr.includes("C")) {
                  setgblEnrollDisable(true);
                }
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleDialogBoxClose = () => {};

  useEffect(() => {
    if (
      location.state.type === "Program" ||
      location.state.type === "eLearning"
    ) {
      setelearningProgramGrid(true);
      setclassroomTrainingGrid(false);
      GetEL_P_Details();
    } else {
      setelearningProgramGrid(false);
      setclassroomTrainingGrid(true);
      GetCLS_Training_Details();
    }
  }, []);

  useEffect(() => {
    axios

      .get(
        PUBLIC_URL + "/api/ELearning/AvailableLearning/EL/EquivalenceDetails",

        {
          params: {
            userId: sessionStorage.getItem("userId"),

            LnchId: location.state.course_id,

            Culture: "en-US",

            CrsType: "C",
          },
        }
      )

      .then((res) => {
        setEquivalenceData(res.data);
        if (res.data.Table.length === 0) {
          setEquivalenceFlag(false);
        } else setEquivalenceFlag(true);
      })

      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    if (
      location.state.type === "Program" ||
      location.state.type === "eLearning"
    ) {
      setelearningProgramGrid(true);
      setclassroomTrainingGrid(false);
      GetEL_P_Details();
    } else {
      setelearningProgramGrid(false);
      setclassroomTrainingGrid(true);
      GetCLS_Training_Details();
    }
  }, []);

  useEffect(() => {
    axios
      .get(
        PUBLIC_URL + "/api/ELearning/AvailableLearning/EL/EquivalenceDetails",
        {
          params: {
            userId: sessionStorage.getItem("userId"),
            LnchId: location.state.course_id,
            Culture: "en-US",
            CrsType: "C",
          },
        }
      )
      .then((res) => {
        setEquivalenceData(res.data);
        if (res.data.Table.length === 0) {
          setEquivalenceFlag(false);
        } else setEquivalenceFlag(true);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const startDateFixed = () => {
    var startDateTemp = new Date(location?.state?.start_date);

    startDateTemp.setDate(startDateTemp.getDate() + 1);

    var startDateLocal = startDateTemp.toISOString().split("T")[0];
    return startDateLocal;
  };

  return (
    <div>
      <Navbar />

      <Dialog
        fullWidth
        open={dialogBoxOpen}
        onClose={handleDialogBoxClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"There are following errors"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {/* add the input forms here */}
            {validationMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {/* <Button onClick={handleDialogBoxClose}>Submit</Button> */}
          <Button
            onClick={() => {
              setdialogBoxOpen(false);
              setvalidationMessage("");
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Button
        onClick={() => {
          navigate("/availablelearning");
        }}
        style={{ marginTop: "75px" }}
      >
        {t("rbkey_BckLBL")}
      </Button>
      <Container fluid>
        <Row>
          <Col>
            {EL_P_ExpiredFlag ? (
              <Stack sx={{ width: "100%" }} spacing={2}>
                <Alert severity="error">
                  {(location.state.type === "eLearning" ||
                    location.state.type === "Program") &&
                    // <>
                    //   <AlertTitle>Expiry Alert</AlertTitle>
                    //   {t("rbkey_ExprdNtfctnsMSG")}
                    // </>
                    (location.state.validationforuser ? (
                      <>
                        <AlertTitle>Expiry Alert</AlertTitle>
                        {t("rbkey_ExprdNtfctnsMSG")}
                      </>
                    ) : (
                      <>
                        <AlertTitle>Access Denied</AlertTitle>
                        {t("rbkey_AccssNtfctnsMSG")}
                      </>
                    ))}
                  {location.state.type === "Classroom Training" && (
                    <>
                      <AlertTitle>Access Denied</AlertTitle>
                      {t("rbkey_AccssNtfctnsMSG")}
                    </>
                  )}
                </Alert>
              </Stack>
            ) : (
              <Card>
                <Card.Header as="h5" className="CardHeader">
                  <span>
                    <span>{t("rbkey_LrnngCntr")}</span> |{" "}
                    <span>{t("rbkey_AvlblLrnngLBL")}</span>
                  </span>
                  <span style={{ float: "right" }} className={classes.mt30}>
                    {location.state.type === "eLearning"
                      ? `${t("rbkey_ELrrngLbl")}`
                      : location.state.type === "Program"
                      ? `${t("rbkey_PrgrmLbL")}`
                      : `${t("rbkey_ClssrmTrnngsLBL")}`}
                  </span>
                </Card.Header>
                <Card.Body>
                  {elearningProgramGrid && (
                    <Row>
                      <Col sm={2}>
                        <img
                          className="offerLogo"
                          src={location.state.imgSrc}
                        />
                      </Col>

                      <Col sm={6} className={classes.ml50}>
                        <Row style={{ padding: "0 0 2% 0" }}>
                          <Col>
                            <span style={{ fontWeight: "bold" }}>
                              {!location.state.course_name
                                ? "NA"
                                : location.state.course_name}
                            </span>
                          </Col>
                        </Row>
                        <Row style={{ padding: "0 0 2% 0" }}>
                          <Col>
                            <span>
                              {t("rbkey_launchDate_LBL")} :{" "}
                              {!location?.state?.start_date
                                ? "NA"
                                : // : location?.state?.start_date?.split("T")[0]
                                  // startDateFixed()
                                  formatTime(
                                    location?.state?.start_date,
                                    "date"
                                  )}
                            </span>
                          </Col>

                          {!location.state.isAvailableForever && (
                            <Col>
                              <span>
                                {t("rbkey_availableTill_LBL")} :{" "}
                                {!location?.state?.end_date
                                  ? "NA"
                                  : location?.state?.end_date?.split("T")[0]}
                              </span>
                            </Col>
                          )}
                          <Col>
                            <span>
                              {t("rbkey_CrdtHrsLBL")} :{" "}
                              {!location.state.credit_hours
                                ? "NA"
                                : location.state.credit_hours}
                            </span>
                          </Col>
                        </Row>

                        <Row style={{ padding: "0 0 2% 0" }}>
                          <Col>
                            {location.state.type === "eLearning" ? (
                              <span>{t("rbkey_ElrnngCrsCdLBL")} : </span>
                            ) : (
                              <span>{t("rbkey_PrgrmCdLbl")} : </span>
                            )}
                            {location.state.VC_ILT_CD}
                          </Col>
                          <Col>
                            <span>
                              {t("rbkey_TpcLBL")} :{" "}
                              {location.state.topic_name !== undefined &&
                              location.state.topic_name !== " " &&
                              location.state.topic_name !== null
                                ? location.state.topic_name
                                : "NA"}
                            </span>
                          </Col>
                        </Row>
                        <Row style={{ padding: "0 0 2% 0" }}>
                          <Col sm={4}>
                            <span>{t("rbkey_PrgrssFrMblLBL")} </span>
                          </Col>

                          <Col sm={7}>
                            <ProgressBar
                              now={location.state.progress}
                              label={`${location.state.progress}%`}
                              variant="PROGRESSBAR_COLOR"
                              style={{ marginTop: 3 }}
                            />
                          </Col>
                          <Col sm={1} style={{ padding: "0 0 2% 0" }}>
                            {location.state.progress == 0 && "0%"}
                          </Col>
                        </Row>
                        <Row style={{ padding: "0 0 2% 0" }}>
                          <Col>
                            <span>
                              {t("rbkey_DscrptnLBL")} :{" "}
                              {!location.state.description
                                ? "NA"
                                : location.state.description}
                            </span>
                          </Col>
                        </Row>
                      </Col>

                      <Col sm={3}>
                        {elearningProgramGrid && startbtnVisibility && (
                          <Button
                            variant="contained"
                            onClick={(e) => {
                              handleStartbtn(e);
                            }}
                          >
                            {t("rbkey_StrtLBL")}
                          </Button>
                        )}
                      </Col>
                    </Row>
                  )}

                  {classroomTrainingGrid && (
                    <>
                      <Row style={{ padding: "0 0 2% 0" }}>
                        <Col sm={2}>
                          <img
                            className="offerLogo"
                            src={location.state.imgSrc}
                          />
                        </Col>
                        <Col sm={8} className={classes.ml50}>
                          <div style={{ padding: "0 0 2% 0" }}>
                            <span style={{ fontWeight: "bold" }}>
                              {classroomData.Table?.map(
                                (item) => item.VC_ILT_NM
                              )}
                            </span>
                          </div>
                          <Row style={{ padding: "0 0 2% 0" }}>
                            <Col>
                              <span style={{ fontWeight: "bold" }}>
                                {t("rbkey_CrdtHrsLBL")} :{" "}
                              </span>
                              <span>
                                {classroomData.Table?.map(
                                  (item) => item.NM_CRDT_HRS
                                )}
                              </span>
                            </Col>
                            <Col>
                              <span style={{ fontWeight: "bold" }}>
                                {t("rbkey_TpcLBL")} :{" "}
                              </span>

                              {classroomData.Table?.map(
                                (item) => item.VC_ILT_KND_NM
                              )}
                            </Col>
                          </Row>
                          <Row style={{ padding: "0 0 2% 0" }}>
                            <Col>
                              <span style={{ fontWeight: "bold" }}>
                                {t("rbkey_TrnngCdLbl")} :{" "}
                              </span>
                              {location.state.VC_ILT_CD}
                            </Col>
                          </Row>
                          <Row style={{ padding: "0 0 2% 0" }}>
                            <Col>
                              <span style={{ fontWeight: "bold" }}>
                                {t("rbkey_DscrptnLBL")} :{" "}
                              </span>
                              <span>
                                {classroomData.Table?.map((item) =>
                                  item.VC_DSCRPTN === undefined
                                    ? "NA"
                                    : removeTags(item.VC_DSCRPTN)
                                )}
                              </span>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </>
                  )}

                  {elearningProgramGrid && (
                    <Paper className={classes.mt20}>
                      <Grid rows={data} columns={columns}>
                        <TreeDataState />
                        <CustomTreeData getChildRows={getChildRows} />
                        <TableDevExpress
                          columnExtensions={tableColumnExtensions}
                        />
                        <TableHeaderRow />
                        <TableTreeColumn for="VC_Node_NM" />
                      </Grid>
                    </Paper>
                  )}

                  {classroomTrainingGrid && (
                    <Row>
                      <Col>
                        <TableContainer component={Paper}>
                          <Table aria-label="collapsible table">
                            <TableHead>
                              <TableRow>
                                <TableCell style={{ fontWeight: "Bold" }}>
                                  {t("rbkey_OffrCdLbl")}
                                </TableCell>
                                <TableCell
                                  style={{ fontWeight: "Bold" }}
                                  align="right"
                                >
                                  {t("rbkey_BtchNmLBL")}
                                </TableCell>
                                <TableCell
                                  style={{ fontWeight: "Bold" }}
                                  align="right"
                                >
                                  {t("rbkey_StrtDtLBL")}
                                </TableCell>
                                <TableCell
                                  style={{ fontWeight: "Bold" }}
                                  align="right"
                                >
                                  {t("rbkey_EdDtLBL")}
                                </TableCell>
                                <TableCell
                                  style={{ fontWeight: "Bold" }}
                                  align="right"
                                >
                                  {t("rbkey_VnLBL")}
                                </TableCell>
                                <TableCell
                                  style={{ fontWeight: "Bold" }}
                                  align="right"
                                >
                                  {t("rbkey_InstrctrLBL")}
                                </TableCell>
                                <TableCell
                                  style={{ fontWeight: "Bold" }}
                                  align="right"
                                >
                                  {t("rbkey_ActnLBL")}
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {classRoomGridData?.Table?.map((row, index) => (
                                <ClassRoomRowMUI
                                  key={row.NM_ILT_ID}
                                  row={row}
                                />
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Col>
                    </Row>
                  )}
                </Card.Body>
              </Card>
            )}
          </Col>
        </Row>
      </Container>
    </div>
  );
};
